@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

* {
	margin: 0 auto;
	padding: 0px;
}

body {
	background-color: blanchedalmond;
	color: white;
	font-family: "Inknut Antiqua", serif;
	margin-top: 0px;
}

footer {
	/* background: yellow; */
	color: #bbb;
	/* padding: 10px; */
	text-align: center;
	margin-top: auto;
	font-size: 0.6em;
	width: 100%;
	position: absolute;
	bottom: 1rem;
	margin: 0;
}

.App {
	text-align: center;
}

.landing-page,
.about-page {
	position: relative;
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	/* border: 3px blue solid;
	background-color: blue; */
}

.work-page {
	position: relative;
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
}

.circle-about-link {
	width: 100px;
	height: 100px;
	cursor: pointer;
	display: flex;
	border-radius: 50%;
	background-color: rgb(51, 19, 80);
	text-align: center;
	margin-top: auto;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 5%;
	right: 5%;
	margin: 0;
	text-decoration: none;
	animation: pulse 5s infinite ease-out;
}

@keyframes pulse {
	0% {
		transform: scale(0.9); /* scaling to 0 */
	}
	50% {
		transform: scale(1.1); /* increasing the size */
	}
	70% {
		transform: scale(1); /* decreasing the size */
	}
	100% {
		transform: scale(0.9); /* seeting back to initial size */
	}
}

.landing-square {
	width: 80vw;
	height: 80vh;

	position: absolute;
	/* background-color: rgb(225, 206, 206); */
	border: 15px black solid;
	border: 15px pink solid;
	mix-blend-mode: difference;
}
.landing-square-about {
	width: 80vw;
	height: 80vh;
	display: flex;
	position: absolute;
	/* background-color: rgb(225, 206, 206); */
	border: 15px pink solid;
	mix-blend-mode: difference;
}
.landing-square-work {
	width: 80vw;
	height: 80vh;
	position: absolute;
	border: 15px pink solid;
	text-align: left;
	mix-blend-mode: difference;
}

.cv-title {
	/* padding: 2rem; */
	width: 100%;
	padding-bottom: 0;
	color: white;
	text-align: left;
}

.cv-container {
	display: flex;
	flex-wrap: wrap;
	width: 70vw;
	height: 70%;
}

.cv-text {
	font-family: "poppins", sans-serif;
	/* padding: 2rem; */
	text-align: left;
	width: 30%;
	/* border: 1px red solid; */
	/* margin-bottom: 2rem; */
	position: relative;
	cursor: pointer;
	color: rgb(125, 124, 124);
	padding: 0.5em;
	/* background-color: rgb(244, 215, 220); */
}

.aframe-scene {
	width: 100%;
	height: 100%;
	filter: blur(30px);
}

.landing-header {
	height: 100%;
	width: 100%;
	position: absolute;
	/* border: 1px red solid; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.title {
	color: rgb(205, 190, 190);
	z-index: 1000;
	mix-blend-mode: difference;
	mix-blend-mode: overlay;
	display: flex;
	font-size: 6rem;
	line-height: 5.5rem;
	letter-spacing: -0.2rem;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	animation: 2s ease-out 0s 1 fadeIn;
	/* pointer-events: none; */
	/* border: 3px blue solid; */
}

.title:hover {
	color: white;
	animation: 1s ease-out 0s 1 fadeIn;
}

.landing-sub-title {
	color: rgb(62, 18, 10);
	font-family: "poppins", sans-serif;
	/* font-weight: 300; */
	text-transform: uppercase;
	position: absolute;
	mix-blend-mode: overlay;
	padding-top: 2rem;
	z-index: 10000;
	font-size: 0.9rem;
	/* cursor: pointer; */
}

.landing-picture {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	margin-top: 0;
	animation: 1s ease-out 0s 1 fadeIn;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.about-text {
	padding: 3rem;
	/* font-family: "poppins", sans-serif; */
	/* font-size: 1.5rem; */
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin-top: 10rem;

	line-height: 1.5rem;
	/* text-align: left; */
}

.email-link {
	text-decoration: none;
	color: white;
	cursor: pointer;
}

.App-header {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
}

.nav {
	position: relative;
}

.contact {
	position: absolute;
	margin-top: 0;
	text-align: left;
	color: white;
}

.project-sub {
	font-weight: 300;
	font-style: italic;
	font-size: 00.6rem;
}

.page-title {
	line-height: 1.5rem;
	padding: 0;
}

.project-texts {
	position: absolute;
	align-items: center;
	font-weight: 700;
	/* visibility: hidden; */
	/* opacity: 0; */
	z-index: 1000;
}

/* .project-title {
	font-weight: 700;
} */

a {
	text-decoration: none;
	color: black;
	cursor: pointer;
}

a:hover {
	color: orangered;
}

.project-image {
	/* background-size: cover; */

	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	/* height: 100%; */
	/* max-width: 50vw; */
}

.movie-img {
	width: 300px;
	position: absolute;
	background-color: black;
	mix-blend-mode: normal;
	color: white;
	z-index: 100;
	/* border: 1px red solid; */
	margin: 10rem;
	margin-top: -5rem;
	animation: 1s ease-out 0s 1 fadeIn;
	/* padding: 0.5em; */
}

@media screen and (max-width: 1200px) {
	.cv-text {
		width: 30%;
		padding: 0.5em;
		font-size: 0.9rem;
		/* background-color: rgb(244, 215, 220); */
	}
	.cv-container {
		width: 80vw;
	}
}

@media screen and (max-width: 1000px) {
	.title {
		font-size: 6rem;
		line-height: 6rem;
	}

	.about-text {
		font-size: 1rem;
	}

	.landing-square,
	.landing-square-about,
	.landing-square-work {
		height: 85vh;
		width: 85vw;
	}

	.movie-img {
		margin: 8rem;
		margin-top: -8rem;
	}
}

@media screen and (max-width: 735px) {
	.cv-text {
		/* width: 50%; */
		padding: 0.5em;
		font-size: 0.85rem;
		/* background-color: rgb(244, 215, 220); */
	}
	.cv-container {
		width: 80vw;
		height: 75%;
	}
}
@media screen and (max-width: 619px) {
	.cv-text {
		font-size: 0.83rem;
	}
	.movie-img {
		margin: 1rem;
		margin-top: 2rem;
	}
}

@media screen and (max-width: 550px) {
	.title {
		font-size: 5rem;
		line-height: 5rem;
		z-index: 10000;
	}
	.landing-sub-title {
		font-size: 1rem;
		line-height: 5rem;
		padding-top: 12rem;
	}

	.about-text {
		font-size: 0.8rem;
		padding: 2rem;
	}

	.cv-text {
		font-size: 0.75rem;
		width: 100%;
		margin: 0;
		padding-left: 2em;
	}

	.cv-container {
		height: auto;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		padding-top: 2rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.landing-square,
	.landing-square-about {
		height: 90vh;
	}

	.work-page {
		height: auto;
	}

	/* .work-page > .landing-picture {
		display: none;
	} */

	.landing-square-work {
		display: none;
	}

	footer {
		display: none;
	}

	.movie-img {
		width: 200px;
	}

	.circle-about-link {
		width: 70px;
		height: 70px;
		top: 4%;
		right: 4%;
	}
}
@media screen and (max-width: 450px) {
	.title {
		font-size: 4rem;
		line-height: 4rem;
	}
}
@media screen and (max-width: 400px) {
	.title {
		font-size: 3rem;
		line-height: 3rem;
	}

	.cv-text {
		font-size: 0.6rem;
		width: 100%;
		margin: 0;
		padding-left: 2em;
	}
	.cv-container {
	}
}
